/* eslint-disable no-console,no-undef, camelcase */
/* eslint-disable react/no-unknown-property */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getActiveEvents } from "../../../redux/actions";
import { LayoutNew } from "../../EventDetails/styles";
import { BuddyTabContainer, BuddyTab } from "../../PeopleHomeV2/styles";
import HoverButton from "../../common/HoverButton";
import { CompletedChallengeWrapper } from "../styles";
import { WolibaCard, WolibaButton, NoEvents } from "../CompanyLiveEvents/styles";
import AboutTheEventModal from "../AboutTheEventModal";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import Waiting from "../../Waiting";
import { ImageUrl } from "../../../utils/constants";
import moment from "moment";
import { convertDateInTimezone, convertMonthFromDate } from "../../../utils/methods";
import LazyImage from "../../common/LazyImage/LazyImage";
import {CoachCard} from '../SingleEventV2/styles';

class ScheduleEventsMain extends Component {
  constructor() {
    super();
    this.state = {
      pageNumber: 1,
      tabValue: 2,
      showAboutEventModal: false,
      eventCalendarId: null,
    };
  }

  componentDidMount() {
    const { getActiveEvents } = this.props;
    const { pageNumber } = this.state;
    getActiveEvents(pageNumber);
  }

  updateTabStatus = () => {
    this.props.history.push("/events/live");
  };

  toggleModalV2 = (event) => {
    if(event){
      event.stopPropagation();
    }
    this.setState((prev) => ({
      showAboutEventModal: !prev.showAboutEventModal,
    }));
  };

  toggleModal = (id, event) => {
    event.stopPropagation();
    this.setState((prev) => ({
      showAboutEventModal: !prev.showAboutEventModal,
      eventCalendarId: id,
    }));
  };

  backButton = () => (
    <svg
      width="20px"
      height="auto"
      viewBox="-5 0 25 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ui-gambling-website-lined-icnos-casinoshunter"
          transform="translate(-1913.000000, -158.000000)"
          fill="#0D4270"
          fillRule="nonzero"
        >
          <g id="1" transform="translate(1350.000000, 120.000000)">
            <path
              d="M566.453517,38.569249 L577.302459,48.9938158 L577.39261,49.0748802 C577.75534,49.423454 577.968159,49.8870461 578,50.4382227 L577.998135,50.6228229 C577.968159,51.1129539 577.75534,51.576546 577.333675,51.9774469 L577.339095,51.9689832 L566.453517,62.430751 C565.663694,63.1897497 564.399001,63.1897497 563.609178,62.430751 C562.796941,61.650213 562.796941,60.3675924 563.609432,59.5868106 L573.012324,50.5572471 L563.609178,41.4129456 C562.796941,40.6324076 562.796941,39.349787 563.609178,38.569249 C564.399001,37.8102503 565.663694,37.8102503 566.453517,38.569249 Z"
              id="left"
              transform="translate(570.500000, 50.500000) scale(-1, 1) translate(-570.500000, -50.500000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );

  liveEventsAPICall = () => {
    // this.props.getActiveEvents(pageNumber);
  };

  headerView = () => {
    const { tabValue } = this.state;
    return (
      <BuddyTabContainer marginBottom="25px">
        <div className="backButton">
          <HoverButton
            title={this.props.t("Back")}
            svgPath={this.backButton()}
            onClick={() => this.props.history.push("/events")}
            width={"22"}
            height={"19"}
          />
        </div>
        <BuddyTab
          width
          padding
          active={tabValue === 1}
          onClick={() => this.updateTabStatus()}
          // style={{color:'#005c87'}}
          color={"#005c87"}
          margin={"10px 0px 10px 10px"}
        >
          {this.props.t("Live")}
        </BuddyTab>
        <BuddyTab width padding active={tabValue === 2} margin={"10px 0px"}>
          {" "}
          {this.props.t("My Schedule")}
        </BuddyTab>
      </BuddyTabContainer>
    );
  };

  onScroll = (e, events) => {
    const { pageNumber } = this.state;
    const { totalCount } = this.props;
    const bottom =
      Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10)) ===
        Math.round(e.target.clientHeight + 1) ||
      Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10) + 1) ===
        Math.round(e.target.clientHeight + 1);
    if (events.length < totalCount) {
      if (bottom) {
        this.setState(
          {
            pageNumber: pageNumber + 1,
          },
          () => {
            this.liveEventsAPICall(this.state.pageNumber);
          }
        );
      }
    }
  };

  handleWolibaLive = (id, attending, event) => {
    event.stopPropagation();
    this.props.attendEvent(id, attending, 1);
  };

  sortDataByDate = (data) => {
    const sortedData = data.sort((a, b) => (
      new Date(a.start_date) - new Date(b.start_date)
    ));

    return sortedData;
  };

  redirection = (route) => {
    this.props.history.push(route)
  }

  render() {
    const { showAboutEventModal, eventCalendarId, tabValue } = this.state;
    const { activeEvents, role } = this.props;
    if (!activeEvents || isUndefined(activeEvents) || isNull(activeEvents)) {
      return <Waiting />;
    }
    let ScheduleEvent = [];
    if (activeEvents && !isUndefined(activeEvents) && !isNull(activeEvents)) {
      activeEvents &&
        activeEvents.map((data) => {
          if (data.is_attending === 1) {
            ScheduleEvent.push(data);
          }
        });
    }
    let scheduledLiveEvents=ScheduleEvent && ScheduleEvent.length?this.sortDataByDate(ScheduleEvent):[];
    return (
      <LayoutNew>
        <div className="containerDiv">
          {this.headerView()}
          <CompletedChallengeWrapper
            // overflow
            // height
            // onScroll={(e) => this.onScroll(e, activeEvents)}
          >
            {tabValue === 2 && scheduledLiveEvents && scheduledLiveEvents.length
              ? scheduledLiveEvents.map((data, index) => (
                <WolibaCard
                  width="calc((100% - 50px) / 3)"
                  margin={ (index + 1) % 3 === 0 ? "0 0 25px 0" : "0 25px 25px 0"}
                  key={index}
                >
                  {/* <div className="eventLabel">
                    <div className="label" style={{minHeight: "24px"}}>
                      {data.is_global_event=="1" ? this.props.t("Woliba Live") : this.props.t("Company Live")}
                    </div>
                  </div> */}
                  {data?.coach_details?<CoachCard onClick={() =>this.props.history.push(`/events/${data.id}`)}>
                    <div className='cardWrapper'>
                      <div className='imagecard'>
                        <img src={`${ImageUrl}/${data?.coach_details?.image}`} />
                      </div>
                      <div className='coach-details'>
                        <div className='title'>{data?.coach_details?.name}</div>
                        <div className='info'>{data?.coach_details?.education}</div>
                      </div>
                    </div>
                  </CoachCard>:
                    <div
                      className="imageCard"
                      onClick={() =>
                        this.props.history.push(`/events/${data.id}`)
                      }
                    >
                      <img src={`${ImageUrl}/${data.image}`} alt="category" />
                    </div>}
                  <div className="bg-opacity"> </div>
                  <div className="detailsCard">
                    <div className="titleCard">
                      <div className="title">{data.title}</div>
                      <div className="icon">
                        <img
                          src="/public/images/NewDashboardV2/infoIcon.png"
                          alt="info"
                          onClick={(event) =>
                            this.toggleModal(data.id, event)
                          }
                        />
                      </div>
                    </div>
                    <div className="time">
                      {convertMonthFromDate(moment(convertDateInTimezone(data && data.start_date)._d).format("MMM DD, hh:mm a"), this.props)}{" "}({data.abbreviation}) |{" "}
                      {data.event_location ? this.props.t("Onsite") : this.props.t("Virtual")}
                    </div>
                    {data.is_lunch_and_learn_event === 1 && data.event_link ? null : <WolibaButton
                      onClick={(event) =>this.handleWolibaLive(
                        data.id,data.is_attending,event
                      )}
                      
                      background={data.is_attending &&
                        "white"
                      }
                      margin={data && data.attendees_list && data.attendees_list.length < 3? "auto 27px auto auto": "auto 15px auto auto"}
                      style={{justifyContent:'center'}}
                      boxShadow={!data.is_attending && '0px 4px 12px rgba(105, 194, 255, 0.5)'}
                      color={data.is_attending && "#005c87"}
                      border={data.is_attending && "1px solid #005c87"}
                    >
                      <div className="title">
                        {data.is_attending ? (
                          <img
                            src="/public/images/Events/rightMark.png"
                            alt="arrow"
                          />) : null}
                        {!data.is_attending? this.props.t("Reserve Spot"): this.props.t("Spot Reserved")}
                      </div>
                      {/* <div className="participants">
                        {data && data.attendees_list && data.attendees_list.length > 0 && (
                          <div className="image">
                            <img
                              src={`${ImageUrl}/${data.attendees_list[0].profile_image}`}
                              alt="category"
                            />
                          </div>)}
                        {data && data.attendees_list && data.attendees_list.length > 1 && (
                          <div className="image">
                            <img
                              src={`${ImageUrl}/${data.attendees_list[1].profile_image}`}
                              alt="category"
                            />
                          </div> )}
                        {data && data.attendees_list && data.attendees_list.length > 2 && (
                          <div className="count">
                            {data.attendees_list.length - 2}
                          </div> )}
                      </div> */}
                    </WolibaButton>}
                  </div>
                  {eventCalendarId === data.id && showAboutEventModal && (
                    <AboutTheEventModal
                      showModal={showAboutEventModal}
                      onHide={this.toggleModalV2}
                      title={(data && data.title) || ""}
                      body={(data && data.body) || ""}
                    />)}
                </WolibaCard>))
              : 
              <NoEvents>
                <LazyImage div={"imageCard"} src={`${ImageUrl}${"/images/Events/NoEvents.png"}`} alt="No-Events" />
                {activeEvents && activeEvents.length>0?
                  <div className="text" style={{color:'#005c87',fontFamily:'rubik'}}>
                    {this.props.t("Sorry you don’t have any events schedule please go to live event and register your spot to schedule it!")}
                  </div>:
                  role==="WELLNESS_ADMIN"||role==="ADMIN"?
                    <div className="text" style={{color:'#005c87',fontFamily:'rubik'}}>
                      {this.props.t("There is no scheduled Events in the here for your company?")}
                    </div>:
                    <div className="text" style={{color:'#005c87',fontFamily:'rubik'}}>
                      {this.props.t("There is no Live Event Created. Soon we will add a few events till then See are OnDemand Classes")}
                    </div>}
                <div className="buttonContainer">
                  <div className="buttons">
                    <WolibaButton style={{color:'#005c87'}}>
                      {activeEvents && activeEvents.length>0?
                        <div className="button-title" onClick={() =>this.redirection("/events/live")}>
                          {this.props.t("Go to Live Event")}
                        </div>:role==="WELLNESS_ADMIN"||role==="ADMIN"?
                          <div className="button-title" onClick={() =>this.redirection("/company/events/create")}>
                            {this.props.t("Create Event")}
                          </div>:
                          <div className="button-title" onClick={() =>this.redirection("/events/ondemand/1")}>
                            {this.props.t("Go to Dashboard")}
                          </div>
                      }
                    </WolibaButton>
                  </div>
                </div>
              </NoEvents>
            }
          </CompletedChallengeWrapper>
        </div>
      </LayoutNew>
    );
  }
}

ScheduleEventsMain.propTypes = {
  history: PropTypes.object.isRequired,
  getActiveEvents: PropTypes.func,
  activeEvents: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  attendEvent: PropTypes.func,
  role: PropTypes.string,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  activeEvents: state.events.activeEvents,
});

const mapDispatchToProps = (dispatch) => ({
  getActiveEvents: (pageNo) => dispatch(getActiveEvents(pageNo)),
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ScheduleEventsMain));
